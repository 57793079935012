<template>
  <div class="dark-bg fullheight" style="overflow: hidden">
    <div class="splitside">
      <div style="height: 15vh">
        <div class="center-img2 pt-3">
          <img src="/resources/images/skygruppen.png" />
        </div>
        <h4 class="text-light mt-3" style="text-align: center">VI BYGGER FREMTIDENS TALENTER OG LEDERE</h4>
      </div>
      <div class="row">
        <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/skycall-dark.png"/>
        </div>
          <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/skyfacility-dark.png" />
        </div>
        <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/skyestate-dark.png" />
        </div>
        <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/skymarketing-dark.png" />
        </div>
        <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/skyfinance-dark.png" />
        </div>
        <div class="company-logo col-md-12 p-3">
          <img src="/resources/images/peur-dark.png" style="padding: 25px"/>
        </div>
      </div>
    </div>
    <div class="splitside2">
      <Views />
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import moment from 'moment'
import Views from '@/views/TV/views'

export default {
  components: {
    Views,
  },
  data() {
    return {
      posts: [],
      loaded: false,
      hasPosts: false,
    }
  },
  methods: {
    moment,
    getRecentPosts() {
      const ref = firestore.collection('news').where('status', '==', 'published').orderBy('date', 'desc')
      ref.onSnapshot(snapshot => {
        this.posts = []
        snapshot.forEach(doc => {
          this.posts.push(doc.data())
        })
        this.hasPosts = Boolean(this.posts.length)
        this.loaded = true
        this.$forceUpdate()
      })
    },
  },
  watch: {
    posts(oldPosts, newPosts) {
      this.hasPosts = Boolean(this.posts.length)
      console.log(this.hasPosts, this.posts)
      this.$forceUpdate()
    },
  },
}
</script>

<style>
.hide-el {
  display: none!important;
}
.iframetv2 {
  width: 100%;
  height: 100vh;
  background: #fff;
}
.fullheight {
  height: 100vh;
;
}
.splitside {
  height: 100vh;
  width: 20%;
  float: left;
  padding: 0rem 2rem 2rem 2rem;
}
.splitside2 {
  height: 100vh;
  width: 80%;
  float: left;
}
.dark-bg {
  background: #000000;
}
.center-img2 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.center-img2 img{
  width: 220px;
  height: calc(200/3.49)px;
}
.dark-card {
  background: #171717;
  border-color: #171717;
  border-radius: 16px;
}
.dark-card {
  color: #ccc
}
.company-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.company-logo img{
  width: 200px;
  height: calc(200px/3.49)px;
}
</style>
